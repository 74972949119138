import React, {
    useEffect,
    useState,
    useRef,
    useMemo
} from "react";
import Typewriter from "typewriter-effect";

import "./custom_typewriter.css";

/**
 * Props interface for the CustomTypeWriter component.
 *
 * @interface CustomTypewriterProps
 */
interface CustomTypewriterProps {
    /** Array of strings to be typed */
    strings?: string[];
    /** Delay in milliseconds before starting the typewriter effect */
    start_delay?: number;
    /** Whether to loop through the strings continuously */
    loop?: boolean;
    /** Speed of deleting characters in milliseconds */
    delete_speed?: number;
    /** Delay between typing each character in milliseconds */
    delay?: number;
    /** Total duration for typing all strings in milliseconds */
    duration?: number;
    /** Whether the typewriter can start */
    can_start?: boolean;
}

/**
 * This component creates a typewriter effect for given strings.
 * It supports customizable typing speed, looping, and respects reduced motion preferences.
 *
 * @component
 *
 * @param {CustomTypewriterProps} props - The props for the CustomTypeWriter component
 */
const CustomTypeWriter: React.FC<CustomTypewriterProps> = React.memo(
    ({
         strings = [],
         start_delay = 0,
         loop = true,
         delete_speed = 50,
         delay = 50,
         duration = undefined,
         can_start = false,
     }) => {
        /**
         * State to handle reduced motion preference
         */
        const [reduce_motion, set_reduce_motion] = useState<boolean>(false);
        /**
         * State to control when to show the typewriter
         */
        const [show_typewriter, set_show_typewriter] = useState<boolean>(false);
        /**
         * State to track if typing is complete (for non-loop mode)
         */
        const [typing_complete, set_typing_complete] = useState<boolean>(false);
        /**
         * Ref to store the Typewriter instance
         */
        const typewriter_ref = useRef<any>(null);

        /**
         * Effect to detect and handle reduced motion preference.
         * It sets up a media query listener to respond to changes in motion preference.
         */
        useEffect(() => {
            const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
            set_reduce_motion(mediaQuery.matches);
            const handleChange = () => set_reduce_motion(mediaQuery.matches);
            mediaQuery.addEventListener("change", handleChange);
            return () => mediaQuery.removeEventListener("change", handleChange);
        }, []);

        /**
         * Effect to handle the start delay and can_start prop.
         * It shows the typewriter after the specified delay if can_start is true.
         */
        useEffect(() => {
            if (can_start) {
                const timer = setTimeout(() => {
                    set_show_typewriter(true);
                }, start_delay);
                return () => clearTimeout(timer);
            } else {
                set_show_typewriter(false);
            }
        }, [can_start, start_delay]);

        /**
         * Calculate adjusted delay based on duration prop (if provided).
         * This ensures that all strings are typed within the specified duration.
         */
        const adjusted_delay = useMemo(() => {
            if (duration && strings.length > 0) {
                const total_characters = strings.reduce((max, str) => Math.max(max, str.length), 0);
                const MIN_DELAY = 1;
                return total_characters > 0
                    ? Math.max(Math.floor(duration / total_characters), MIN_DELAY)
                    : delay;
            }
            return delay;
        }, [duration, strings, delay]);

        // If reduced motion is preferred, render all strings at once
        if (reduce_motion) {
            return <span aria-live="polite">{strings.join(' ')}</span>;
        }

        return (
            <div
                className={`typewriter-container ${typing_complete ? "typing-complete" : ""}`}
                aria-live="polite"
            >
                {!show_typewriter ? (
                    // Render a placeholder when typewriter is not shown
                    <span
                        className="typewriter-placeholder"
                        aria-hidden="true"
                    ></span>
                ) : (
                    // Render the Typewriter component
                    <Typewriter
                        options={{
                            strings: loop ? strings : [],
                            autoStart: true,
                            loop: loop,
                            delay: adjusted_delay,
                            deleteSpeed: loop ? delete_speed : 0,
                            cursor: '|',
                        }}
                        onInit={(typewriter) => {
                            typewriter_ref.current = typewriter;

                            if (!loop) {
                                // For non-loop mode, type all strings sequentially
                                strings.reduce((typewriter, str, index) => {
                                    typewriter = typewriter.typeString(str);
                                    if (index < strings.length - 1) {
                                        typewriter = typewriter.pauseFor(50);
                                    }
                                    return typewriter;
                                }, typewriter)
                                    .callFunction(() => {
                                        set_typing_complete(true);
                                    })
                                    .start();
                            } else {
                                // For loop mode, start the typewriter
                                typewriter.start();
                            }
                        }}
                    />
                )}
            </div>
        );
    }
);

export default CustomTypeWriter;