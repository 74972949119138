import React from "react";
import {
    FaGithub,
    FaTwitter,
    FaFacebookF,
    FaLinkedin,
    FaYoutube,
    FaTwitch,
} from "react-icons/fa";

import "./social_icons.css";
import {social_profiles} from "../../global_options";

/**
 * Props interface for the SocialIcons component.
 *
 * @interface SocialIconsProps
 */
interface SocialIconsProps {
    // Add props here.
}

/**
 * This component renders a list of social media icons with links.
 * It displays icons only for the social profiles that are defined in the social_profiles object.
 *
 * @component
 */
const SocialIcons: React.FC<SocialIconsProps> = () => {
    return (
        <div className="stick_follow_icon">
            <ul>
                {/* Conditional rendering for each social media icon */}
                {social_profiles.twitter && (
                    <li>
                        <a href={social_profiles.twitter} target="_blank" rel="noopener noreferrer">
                            <FaTwitter/>
                        </a>
                    </li>
                )}
                {social_profiles.github && (
                    <li>
                        <a href={social_profiles.github} target="_blank" rel="noopener noreferrer">
                            <FaGithub/>
                        </a>
                    </li>
                )}
                {social_profiles.facebook && (
                    <li>
                        <a href={social_profiles.facebook} target="_blank" rel="noopener noreferrer">
                            <FaFacebookF/>
                        </a>
                    </li>
                )}
                {social_profiles.linkedin && (
                    <li>
                        <a href={social_profiles.linkedin} target="_blank" rel="noopener noreferrer">
                            <FaLinkedin/>
                        </a>
                    </li>
                )}
                {social_profiles.youtube && (
                    <li>
                        <a href={social_profiles.youtube} target="_blank" rel="noopener noreferrer">
                            <FaYoutube/>
                        </a>
                    </li>
                )}
                {social_profiles.twitch && (
                    <li>
                        <a href={social_profiles.twitch} target="_blank" rel="noopener noreferrer">
                            <FaTwitch/>
                        </a>
                    </li>
                )}
            </ul>
            <p>Find me</p>
        </div>
    );
};

export default SocialIcons;