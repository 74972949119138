import React from "react";
import {
    Route,
    Routes,
    useLocation
} from "react-router-dom";
import {
    CSSTransition,
    TransitionGroup
} from "react-transition-group";

import {Home} from "../pages/home/home";
import {About} from "../pages/about/about";
import SocialIcons from "../components/social_icons/social_icons";
import WithRouter from "../hooks/with_router";
import NoTextSelection from "../components/no_text_selection";
import NoRightClick from "../components/no_right_click";

/**
 * This component manages route transitions with animations.
 * It wraps the Routes component with CSSTransition for smooth page transitions.
 *
 * @component
 */
const AnimatedRoutes: React.FC = WithRouter(() => {
    // Get the current location using the useLocation hook
    const location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                timeout={{
                    enter: 400,
                    exit: 400,
                }}
                classNames="page"
                unmountOnExit
            >
                <Routes location={location}>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    {/* Fallback route redirects to Home */}
                    <Route path="*" element={<Home/>}/>
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
});

/**
 * This is the main routing component of the application.
 * It wraps the AnimatedRoutes with NoTextSelection and NoRightClick components
 * for additional functionality.
 *
 * @component
 */
const AppRoutes: React.FC = () => {
    return (
        <div className="s_c">
            <NoTextSelection>
                <NoRightClick>
                    <AnimatedRoutes/>
                    <SocialIcons/>
                </NoRightClick>
            </NoTextSelection>
        </div>
    );
}

export default AppRoutes;