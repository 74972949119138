/**
 * Interface representing meta information for the website or application.
 *
 * @interface Meta
 */
interface Meta {
    /** The title of the website or application */
    title: string;
    /** A brief description of the website or application */
    description: string;
}

/**
 * Interface representing social media profile links.
 * All properties are optional to allow for flexibility in profile presence.
 *
 * @interface SocialProfiles
 */
interface SocialProfiles {
    /** URL to the GitHub profile */
    github?: string;
    /** URL to the Facebook profile */
    facebook?: string;
    /** URL to the LinkedIn profile */
    linkedin?: string;
    /** URL to the Twitter profile */
    twitter?: string;
    /** URL to the Twitch profile */
    twitch?: string;
    /** URL to the YouTube channel */
    youtube?: string;
}

/**
 * The text to be used as the logo.
 */
const logo_text: string = "SEIF";

/**
 * Meta information for the website or application.
 */
const meta: Meta = {
    title: "Seif Bourogaa",
    description: "Computer Scientist",
};

/**
 * Social media profile links.
 */
const social_profiles: SocialProfiles = {
    linkedin: "https://www.linkedin.com/in/seifbourogaa/",
};

export {
    meta,
    logo_text,
    social_profiles,
};