/**
 * Interface representing basic information about a person.
 *
 * @interface About
 */
interface About {
    /** The title of the about section */
    title: string;
    /** A description of who the person is */
    who_am_i: string;
}

/**
 * Interface representing contact information.
 *
 * @interface Contact
 */
interface Contact {
    /** The name of the contact */
    name: string;
    /** The phone number of the contact (optional) */
    phone?: string;
    /** The email address of the contact */
    email: string;
    /** The website of the contact */
    website: string;
}

/**
 * Interface representing an item in a work timeline.
 *
 * @interface WorkTimelineItem
 */
interface WorkTimelineItem {
    /** The job title */
    job_title: string;
    /** The company or organization where the job was held */
    where: string;
    /** The date or period of the job */
    date: string;
}

/**
 * Interface representing a skill item with a name and proficiency value.
 *
 * @interface SkillItem
 */
interface SkillItem {
    /** The name of the skill */
    name: string;
    /** The proficiency value of the skill (typically 0-100) */
    value: number;
}

/**
 * Interface representing an education item.
 *
 * @interface EductionItem
 */
interface EductionItem {
    /** The title of the education or degree */
    title: string;
    /** The institution where the education was received */
    where: string;
    /** The period during which the education was undertaken */
    period: string;
    /** A description of the education */
    description: string;
    /** An array of courses taken, or null if not applicable */
    courses: string[] | null;
}

/**
 * About information
 */
const about: About = {
    title: "Who am I?",
    who_am_i: " Result-oriented Software Engineer with expertise in Python and C++. " +
        "In C++, proficient in leveraging libraries such as Boost for productivity " +
        "enhancements and STL for efficient data management and algorithm implementation. " +
        "In Python, proficient in utilizing FastAPI for building high-performance APIs, Pydantic" +
        " for data validation, and Mypy for static type checking. Well-versed in working with " +
        "both SQL and NoSQL. \n\n Committed to software development best practices and designing " +
        "robust system architectures. Passionate about continuous learning. " +
        "Combines technical proficiency with a dedication for excellence." +
        "\n\n Fluent in Swedish and English."
    // ... (rest of the description)
};

/**
 * Contact information
 */
const contact: Contact = {
    name: "Seif Bourogaa",
    email: "seif@bourogaa.dev",
    website: "https://bourogaa.dev/",
}

/**
 * Work timeline information
 */
const work_timeline: WorkTimelineItem[] = [
    {
        job_title: "R&D Engineer",
        where: "AstaZero",
        date: "October, 2023 - Ongoing",
    },
    {
        job_title: "Software Engineer",
        where: "AstaZero",
        date: "April, 2022 - Ongoing",
    },
];

/**
 * Skills information
 */
const skills: SkillItem[] = [
    {
        name: "Python",
        value: 85,
    },
    {
        name: "TypeScript",
        value: 35,
    },
    {
        name: "C++",
        value: 80,
    },
    {
        name: "Docker",
        value: 65,
    },
    {
        name: "GCP",
        value: 30,
    },
    {
        name: "Linux",
        value: 75,
    },
    {
        name: "Git",
        value: 60,
    }
    // ... (other skills here).
];

/**
 * Education information
 */
const education: EductionItem[] = [
    {
        title: "Individual courses within Computer Science",
        where: "Chalmers University of Technology",
        period: "2022 - Ongoing",
        description: " Primary focus on computational methods.",
        courses: [
            "Discrete optimization",
            "Introduction to Data Science and AI",
            "Design of AI Systems",
            "Deep machine learning",
            "Advanced Topics in Machine Learning",
            "Applied machine learning",
            "Computational techniques for large-scale data",
            "Computational methods for bioinformatics"
        ]
    },
    {
        title: "Bachelor of Science in Computer Science",
        where: "Gothenburg University",
        period: "2018 - 2022",
        description: "Primary focus on Software Engineering.",
        courses: null
    }
];

export {
    about,
    contact,
    skills,
    education,
    work_timeline,
}