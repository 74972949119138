import React, {
    useEffect,
    useState,
    useCallback
} from 'react';

import './animated_progressbar.css';

/**
 * Props interface for the AnimatedProgressBar component.
 */
interface AnimatedProgressBarProps {
    /** An array of numbers representing the target values for each progress bar. */
    target_values: number[];
    /** The duration of the animation in milliseconds. Default is 2000ms. */
    duration?: number;
    /** The number of steps in the animation. Controls smoothness. Default is 100. */
    steps?: number;
    /** TODO: This should be take can_start and start_delay props like the custom_typewriter component. */
}

/**
 * AnimatedProgressBar component that displays animated progress bars for given target values.
 * Each bar animates from 0 to its target value over the specified duration.
 */
const AnimatedProgressBar: React.FC<AnimatedProgressBarProps> = React.memo(
    ({
         target_values = [],
         duration = 2000,
         steps = 100
     }) => {
        /**
         * State to hold the current progress for each target value.
         * Initialized with an array of zeros matching the length of target_values.
         */
        const [current_progress, set_current_progress] = useState<number[]>(
            target_values.map(() => 0)
        );

        /**
         * Callback to update the progress of items.
         * This function is memoized to prevent unnecessary re-creations.
         */
        const update_progress = useCallback(() => {
            set_current_progress(prev_progress =>
                prev_progress.map((progress, index) =>
                    // Increase progress, but don't exceed the target value
                    Math.min(progress + (target_values[index] / steps), target_values[index])
                )
            );
        }, [steps, target_values]);

        /**
         * Effect to animate the progress bars.
         * Sets up an interval to update progress and cleans up on unmount.
         */
        useEffect(() => {
            // Calculate the interval between updates
            const interval = duration / steps;
            // Set up the interval to call update_progress
            const timer = setInterval(update_progress, interval);

            // Cleanup function to clear the interval when the component unmounts
            return () => clearInterval(timer);
        }, [duration, steps, update_progress]);

        return (
            <div className="animated-progress-container">
                {current_progress.map((progress, index) => (
                    <div key={index} className="progress-item">
                        <div className="progress mt-2">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                // Set the width of the progress bar as a percentage
                                style={{ width: `${progress}%` }}
                                // ARIA attributes for accessibility
                                aria-valuenow={progress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            >
                                <span className="progress-value">
                                    {/* Display rounded progress value with percentage sign */}
                                    {Math.round(progress)}%
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
);

export default AnimatedProgressBar;