import React from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Link} from "react-router-dom";

import "./home.css";
import {introduction} from "./home_config";
import {meta} from "../../global_options";
import CustomTypeWriter from "../../components/type_writers/custom_typewriter";

// Helper method to extract the strings from the introduction object.
const object_to_ordered_array = (obj: any): string[] => {
    return Object.keys(obj).sort().map((key) => obj[key]);
}

// Define the Home component as a functional component
export const Home: React.FC = () => {
    return (
        // HelmetProvider is used to manage changes to the document head
        <HelmetProvider>
            {/* Main section of the home page */}
            <section id="home" className="home-section">

                {/* Helmet is used to set metadata for the page */}
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{meta.title}</title>
                    <meta name="description" content={meta.description}/>
                </Helmet>

                {/* Main container for the intro content */}
                <div className="intro-container">

                    {/* Background image div */}
                    <div
                        className="background-image"
                        style={{backgroundImage: `url(${introduction.my_img_url})`}}
                    ></div>

                    {/* Wrapper for the main content */}
                    <div className="content-wrapper">
                        {/* Center the content vertically and horizontally */}
                        <div className="content-center">
                            {/* Container for the introduction content */}
                            <div className="intro-content">
                                {/* Main title of the page */}
                                <h2 className="main-title">
                                    <strong>
                                        <CustomTypeWriter
                                            strings={[introduction.title]}
                                            start_delay={0}
                                            loop={false}
                                            delete_speed={0}
                                            duration={1000}
                                            can_start={true}
                                        />
                                    </strong>
                                </h2>
                                {/* Wrapper for the typewriter effect */}
                                <div className="typewriter-wrapper">
                                    {/* Animated text using Typewriter effect */}
                                    <h1 className="typewriter-text">
                                        <CustomTypeWriter
                                            strings={object_to_ordered_array(introduction.animated)}
                                            start_delay={1500}
                                            loop={true}
                                            delete_speed={50}
                                            delay={50}
                                            can_start={true}
                                        />
                                    </h1>
                                </div>
                                {/* Container for action buttons */}
                                <div className="button-group">

                                    {/* About me button */}
                                    <Link to="/about" className="button-link">
                                        <div className="custom-button about-button">
                                            About me
                                            {/* Decorative rings for button animation */}
                                            <div className="button-ring ring-one"></div>
                                            <div className="button-ring ring-two"></div>
                                            <div className="button-ring ring-three"></div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </HelmetProvider>
    );
};