import React, {
    useState,
    useEffect,
    useRef,
    ReactNode,
    ReactElement
} from 'react';

/**
 * Props interface for the ViewportTrigger component.
 *
 * @interface ViewportTriggerProps
 */
interface ViewportTriggerProps {
    /** The child elements to be rendered */
    children: ReactNode;
    /**
     * Callback function to be called when the component enters the viewport
     * @param {React.Dispatch<React.SetStateAction<boolean>>} set_can_start - Function to update the can_start state
     */
    on_enter_viewport: (set_can_start: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

/**
 * This component uses the Intersection Observer API to detect when its contents
 * enter the viewport. When this occurs, it triggers a callback and passes the
 * `can_start` state to all of its children.
 *
 * @component
 */
const ViewportTrigger: React.FC<ViewportTriggerProps> = ({children, on_enter_viewport}) => {
    // State to track whether the component has entered the viewport
    const [can_start, set_can_start] = useState(false);

    // Ref to attach to the wrapper div for intersection observation
    const reference = useRef<HTMLDivElement>(null);

    /**
     * Effect to set up and manage the Intersection Observer
     * This effect runs once on mount and when dependencies change
     */
    useEffect(() => {
        // Create a new Intersection Observer
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Check if the component is intersecting and hasn't started yet
                if (entry.isIntersecting && !can_start) {
                    // Call the callback function with the setter for can_start
                    on_enter_viewport(set_can_start);
                    // Disconnect the observer as it's no longer needed
                    observer.disconnect();
                }
            },
            {threshold: 0.1} // Trigger when 10% of the component is visible
        );

        // Start observing the wrapper div if it exists
        if (reference.current) {
            observer.observe(reference.current);
        }

        // Cleanup function to disconnect the observer when the component unmounts
        return () => {
            observer.disconnect();
        };
    }, [on_enter_viewport, can_start]); // Re-run effect if these dependencies change

    /**
     * Recursively clones a React element and its children, adding the can_start prop
     *
     * @param {ReactElement} element - The React element to clone
     *
     * @returns {ReactElement} A new React element with the can_start prop added
     */
    const clone_element_with_props = (element: ReactElement): ReactElement => {
        // Create a new props object with the original props and the can_start prop
        const child_props = {...element.props, can_start};

        // Recursively clone children
        const children = React.Children.map(element.props.children, child => {
            if (React.isValidElement(child)) {
                return clone_element_with_props(child);
            }
            return child;
        });

        // Create a new element with the updated props and children
        return React.cloneElement(element, child_props, children);
    };

    /**
     * Clone all children, adding the can_start prop to each valid React element
     * This ensures that all child components receive the can_start prop
     */
    const children_with_props = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return clone_element_with_props(child);
        }
        return child;
    });

    // Render the wrapper div with the reference and the cloned children
    return <div ref={reference}>{children_with_props}</div>;
};

export default ViewportTrigger;