import React, {
    useEffect,
    ReactNode
} from 'react';

/**
 * Props interface for the NoRightClick component.
 *
 * @interface NoRightClickProps
 */
interface NoRightClickProps {
    /** The child elements to be rendered */
    children: ReactNode;
}

/**
 * This component prevents the right-click context menu
 * for all of its child elements.
 *
 * @component
 *
 * @param {NoRightClickProps} props - The props for the NoRightClick component
 */
const NoRightClick: React.FC<NoRightClickProps> = ({children}: NoRightClickProps) => {
    /**
     * Effect to add and remove the context menu event listener.
     * This effect runs only once on component mount and cleanup.
     */
    useEffect(() => {
        /**
         * Prevents the default context menu from appearing on right-click.
         *
         * @param {MouseEvent} mouse_event - The mouse event object
         */
        const prevent_context_menu = (mouse_event: MouseEvent): void => {
            mouse_event.preventDefault();
        };

        // Add event listener to disable context menu
        document.addEventListener('contextmenu', prevent_context_menu);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener('contextmenu', prevent_context_menu);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount

    return (
        <div>
            {children}
        </div>
    );
};

export default NoRightClick;