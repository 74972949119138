/**
 * Interface representing the structure of an introduction section.
 *
 * @interface Introduction
 */
interface Introduction {
    /** The main title or name */
    title: string;
    /** Object containing animated text content */
    animated: {
        /** The first line of animated text */
        first: string;
        /** The second line of animated text */
        second: string;
        /** The third line of animated text */
        third: string;
    };
    /** URL or path to the background image */
    my_img_url: string;
}

/**
 * Constant holding the introduction information.
 * This includes the main title, animated text content, and background image URL.
 */
const introduction: Introduction = {
    title: "Seif Bourogaa",
    animated: {
        first: "Computer Scientist with a passion for data.",
        second: "I speak (almost) fluent C++ and Python.",
        third: " System design | Pipelines | Databases",
    },
    my_img_url: "./images/home-background.jpg"
};

export {introduction};