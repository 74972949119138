import React from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
    Location,
    NavigateFunction,
    Params
} from 'react-router-dom';

/**
 * Interface defining the shape of the router props.
 *
 * @interface RouterProps
 */
interface RouterProps {
    /** The current location object */
    location: Location;
    /** An object of key/value pairs of URL parameters */
    params: Params
    /** A function that allows programmatic navigation */
    navigate: NavigateFunction;
}

/**
 * WithRouter Higher-Order Component
 *
 * This HOC wraps a component and injects router-related props (location, params, navigate).
 * It allows class components to access the router context which is normally only available to hooks.
 *
 * @template P - The props type of the wrapped component, which must extend RouterProps
 * @param {React.ComponentType<P>} Component - The component to be wrapped
 * @returns {React.FC<Omit<P, keyof RouterProps>>} A new functional component with router props injected
 */
function WithRouter<P extends RouterProps>(
    Component: React.ComponentType<P>
): React.FC<Omit<P, keyof RouterProps>> {
    /**
     * The wrapped component with router props injected.
     *
     * @template P - The props type of the wrapped component
     * @param {Omit<P, keyof RouterProps>} props - The original props of the wrapped component, excluding router props
     * @returns {JSX.Element} The wrapped component with injected router props
     */
    let ComponentWithRouterProp: React.FC<Omit<P, keyof RouterProps>>;
    ComponentWithRouterProp = (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return (
            <Component
                {...props as P}
                location={location}
                params={params}
                navigate={navigate}
            />
        );
    };

    return ComponentWithRouterProp;
}

export default WithRouter;