import React, {
    useEffect,
    ReactNode,
    CSSProperties
} from 'react';

/**
 * Props interface for the NoTextSelection component.
 *
 * @interface NoTextSelectionProps
 */
interface NoTextSelectionProps {
    /** The child elements to be rendered with text selection disabled */
    children: ReactNode;
}

/**
 * This component prevents text selection for all of its child elements.
 * It uses both CSS properties and JavaScript event listeners to ensure
 * comprehensive text selection prevention across different browsers and scenarios.
 *
 * @component
 *
 * @param {NoTextSelectionProps} props - The props for the NoTextSelection component
 */
const NoTextSelection: React.FC<NoTextSelectionProps> = ({children}: NoTextSelectionProps) => {
    /**
     * Effect to add and remove event listeners for disabling text selection
     * This effect runs once on component mount and cleans up on unmount
     */
    useEffect(() => {
        /**
         * Prevents default text selection behavior
         * @param {Event} event - The event object to prevent default behavior on
         */
        const disable_selection = (event: Event): void => {
            event.preventDefault();
        };

        // Add event listeners to disable text selection
        document.addEventListener('selectstart', disable_selection);
        document.addEventListener('mousedown', disable_selection);

        // Cleanup function to remove event listeners when component unmounts
        return () => {
            document.removeEventListener('selectstart', disable_selection);
            document.removeEventListener('mousedown', disable_selection);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount

    /**
     * CSS properties to disable text selection
     * These properties provide a CSS-based approach to preventing text selection,
     * which complements the JavaScript-based approach used in the useEffect hook
     */
    const no_select_style: CSSProperties = {
        userSelect: 'none',      // Disables text selection for modern browsers
        WebkitUserSelect: 'none' // Disables text selection for older WebKit browsers (e.g., older versions of Safari)
    };

    // Render the children wrapped in a div with text selection disabled
    return (
        <div style={no_select_style}>
            {children}
        </div>
    );
};

export default NoTextSelection;