import React from "react";

import {
    Helmet,
    HelmetProvider
} from "react-helmet-async";
import {
    Container,
    Row,
    Col
} from "react-bootstrap";

import "./about.css";
import {
    about,
    contact,
    skills,
    education,
    work_timeline,
} from "./about_config";
import {meta} from "../../global_options";
import ViewportTrigger from "../../components/viewport_trigger";
import CustomTypeWriter from "../../components/type_writers/custom_typewriter";
import AnimatedProgressBar from "../../components/animated_progressbar/animated_progressbar";

// Define the About component as a React Functional Component
export const About: React.FC = () => {
    return (
        // HelmetProvider is used to manage changes to the document head
        <HelmetProvider>
            {/* Container for the entire About page content */}
            <Container className="About-header">
                {/* Helmet is used for managing changes to the document head */}
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title> About | {meta.title}</title>
                    <meta name="description" content={meta.description}/>
                </Helmet>

                {/* Header Row */}
                <Row className="mb-5 mt-3 pt-md-3">
                    <Col lg="8">
                        <h1 className="display-4 mb-4">
                            About me
                        </h1>
                        {/* Horizontal line for visual separation */}
                        <hr className="t_border my-4 ml-0 text-left"/>
                    </Col>
                </Row>

                {/* Who am I Section */}
                <ViewportTrigger on_enter_viewport={(set_can_start) => set_can_start(true)}>
                    <Row className="sec_sp">
                        <Col lg="5">
                            <h3 className="color_sec py-4">
                                <CustomTypeWriter
                                    strings={[about.title]}
                                    start_delay={0}
                                    loop={false}
                                    delete_speed={0}
                                    duration={1000}
                                />
                            </h3>
                        </Col>
                        <Col lg="7" className="d-flex align-items-center">
                            <div style={{whiteSpace: 'pre-line'}}>
                                <CustomTypeWriter
                                    strings={[about.who_am_i.split("\n").join("\n")]}
                                    start_delay={750}
                                    loop={false}
                                    delete_speed={0}
                                    duration={1000}
                                />
                            </div>
                        </Col>
                    </Row>
                </ViewportTrigger>

                {/* Skills Section */}
                <ViewportTrigger on_enter_viewport={(set_can_start) => set_can_start(true)}>
                    <Row className="sec_sp">
                        <Col lg="5">
                            <h3 className="color_sec py-4">
                                <CustomTypeWriter
                                    strings={["Skills"]}
                                    start_delay={0}
                                    loop={false}
                                    delete_speed={0}
                                    duration={1000}
                                />
                            </h3>
                        </Col>
                        <Col lg="7">
                            {/* Container for all skills */}
                            <div className="skills-container">
                                {skills.map((skill, index) => (
                                    <div key={index} className="skill-item mb-4">
                                        {/* Typewriter for Skill Name with specified duration */}
                                        <CustomTypeWriter
                                            strings={[skill.name]}
                                            start_delay={750}
                                            loop={false}
                                            delete_speed={0}
                                            duration={1000}
                                        />

                                        {/* Progress Bar for Skill Level */}
                                        <AnimatedProgressBar
                                            target_values={[skill.value]}
                                            duration={2000}
                                            steps={60}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </ViewportTrigger>

                {/* Work Timeline Section */}
                <ViewportTrigger on_enter_viewport={(set_can_start) => set_can_start(true)}>
                    <Row className="sec_sp">
                        <Col lg="5">
                            <h3 className="color_sec py-4">
                                <CustomTypeWriter
                                    strings={["Work Timeline"]}
                                    start_delay={0}
                                    loop={false}
                                    delete_speed={0}
                                    duration={1000}
                                />
                            </h3>
                        </Col>
                        <Col lg="7">
                            <table className="work-timeline-table">
                                <thead>
                                <tr>
                                    <th>
                                        <CustomTypeWriter
                                            strings={["Position"]}
                                            start_delay={750}
                                            loop={false}
                                            delete_speed={0}
                                            duration={1000}
                                        />
                                    </th>
                                    <th>
                                        <CustomTypeWriter
                                            strings={["Where"]}
                                            start_delay={750}
                                            loop={false}
                                            delete_speed={0}
                                            duration={1000}
                                        />
                                    </th>
                                    <th>
                                        <CustomTypeWriter
                                            strings={["Period"]}
                                            start_delay={750}
                                            loop={false}
                                            delete_speed={0}
                                            duration={1000}
                                        />
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {work_timeline.map((data, i) => (
                                    <tr key={i}>
                                        <td>
                                            <CustomTypeWriter
                                                strings={[data.job_title]}
                                                start_delay={750}
                                                loop={false}
                                                delete_speed={0}
                                                duration={1000}
                                            />
                                        </td>
                                        <td>
                                            <CustomTypeWriter
                                                strings={[data.where]}
                                                start_delay={750}
                                                loop={false}
                                                delete_speed={0}
                                                duration={1000}
                                            />
                                        </td>
                                        <td>
                                            <CustomTypeWriter
                                                strings={[data.date]}
                                                start_delay={750}
                                                loop={false}
                                                delete_speed={0}
                                                duration={1000}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </ViewportTrigger>

                {/* Education Section */}
                <ViewportTrigger on_enter_viewport={(set_can_start) => set_can_start(true)}>
                    <Row className="sec_sp">
                        <Col lg="5">
                            <h3 className="color_sec py-4">
                                <CustomTypeWriter
                                    strings={["Education"]}
                                    start_delay={0}
                                    loop={false}
                                    delete_speed={0}
                                    duration={1000}
                                />
                            </h3>
                        </Col>
                        <Col lg="7">
                            {education.map((data, i) => (
                                <div className="education_item py-4" key={i}>
                                    <h5 className="education__title">
                                        <CustomTypeWriter
                                            strings={[data.title]}
                                            start_delay={750}
                                            loop={false}
                                            delete_speed={0}
                                            duration={1000}
                                        />
                                    </h5>
                                    <div className="education__info">
                                        <span className="education__where">
                                            <CustomTypeWriter
                                                strings={[data.where]}
                                                start_delay={750}
                                                loop={false}
                                                delete_speed={0}
                                                duration={1000}
                                            />
                                        </span>
                                        <span className="education__period">
                                            <CustomTypeWriter
                                                strings={[data.period]}
                                                start_delay={750}
                                                loop={false}
                                                delete_speed={0}
                                                duration={1000}
                                            />
                                        </span>
                                    </div>
                                    <div className="education__description-wrapper">
                                        <p className="education__description">
                                            <CustomTypeWriter
                                                strings={[data.description]}
                                                start_delay={750}
                                                loop={false}
                                                delete_speed={0}
                                                duration={1000}
                                            />
                                        </p>
                                    </div>
                                    {data.courses && (
                                        <div className="education__courses">
                                            <h6 className="education__courses-title">
                                                <CustomTypeWriter
                                                    strings={["Courses:"]}
                                                    start_delay={750}
                                                    loop={false}
                                                    delete_speed={0}
                                                    duration={1000}
                                                />
                                            </h6>
                                            <ul>
                                                {data.courses.map((course, index) => (
                                                    <li className="education_list" key={index}>
                                                        <CustomTypeWriter
                                                            strings={[`\t– ${course}`]}
                                                            start_delay={750}
                                                            loop={false}
                                                            delete_speed={0}
                                                            duration={1000}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Col>
                    </Row>
                </ViewportTrigger>

                {/* Contact Section */}
                <ViewportTrigger on_enter_viewport={(set_can_start) => set_can_start(true)}>
                    <Row className="sec_sp">
                        <Col lg="5">
                            <h3 className="color_sec py-4">
                                <CustomTypeWriter
                                    strings={["Contact"]}
                                    start_delay={0}
                                    loop={false}
                                    delete_speed={0}
                                    duration={1000}
                                />
                            </h3>
                        </Col>
                        <Col lg="7">
                            <div className="contact_info">
                                <p>
                                    <CustomTypeWriter
                                        strings={[contact.name]}
                                        start_delay={750}
                                        loop={false}
                                        delete_speed={0}
                                        duration={1000}
                                    />
                                </p>
                                {contact.phone && <p>
                                    <CustomTypeWriter
                                        strings={[contact.phone]}
                                        start_delay={750}
                                        loop={false}
                                        delete_speed={0}
                                        duration={1000}
                                    />
                                </p>}
                                <p>
                                    <a href={`mailto:${contact.email}`}>
                                        <CustomTypeWriter
                                            strings={[contact.email]}
                                            start_delay={750}
                                            loop={false}
                                            delete_speed={0}
                                            duration={1000}
                                        />
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href={contact.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <CustomTypeWriter
                                            strings={[contact.website]}
                                            start_delay={750}
                                            loop={false}
                                            delete_speed={0}
                                            duration={1000}
                                        />
                                    </a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </ViewportTrigger>
            </Container>
        </HelmetProvider>
    );
};
