import React, {
    useEffect,
    useRef,
    useState,
    useCallback
} from "react";

/**
 * Interface defining the structure of the device detection utility.
 *
 * @interface IsDeviceType
 */
type IsDeviceType = {
    info: string;
    Android: () => RegExpMatchArray | null;
    BlackBerry: () => RegExpMatchArray | null;
    IEMobile: () => RegExpMatchArray | null;
    iOS: () => RegExpMatchArray | null;
    iPad: () => boolean | null;
    OperaMini: () => RegExpMatchArray | null;
    any: () => boolean | RegExpMatchArray | null;
};

/**
 * IsDevice is an Immediately Invoked Function Expression (IIFE).
 * It returns an object with methods to detect various devices.
 *
 * @constant
 * @type {IsDeviceType}
 */
const IsDevice: IsDeviceType = (() => {
    if (typeof navigator === 'undefined') return {} as IsDeviceType;

    let ua = navigator.userAgent;

    return {
        info: ua,

        /**
         * Checks if the device is running Android.
         *
         * @returns {RegExpMatchArray | null} Match result or null
         */
        Android(): RegExpMatchArray | null {
            return ua.match(/Android/i);
        },

        /**
         * Checks if the device is a BlackBerry.
         *
         * @returns {RegExpMatchArray | null} Match result or null
         */
        BlackBerry(): RegExpMatchArray | null {
            return ua.match(/BlackBerry/i);
        },

        /**
         * Checks if the device is running Internet Explorer Mobile.
         *
         * @returns {RegExpMatchArray | null} Match result or null
         */
        IEMobile(): RegExpMatchArray | null {
            return ua.match(/IEMobile/i);
        },

        /**
         * Checks if the device is running iOS.
         *
         * @returns {RegExpMatchArray | null} Match result or null
         */
        iOS(): RegExpMatchArray | null {
            return ua.match(/iPhone|iPad|iPod/i);
        },

        /**
         * Checks if the device is an iPad.
         *
         * @returns {boolean | null} True if iPad, false or null otherwise
         */
        iPad(): boolean | null {
            return (
                ua.match(/Mac/) &&
                typeof navigator.maxTouchPoints !== 'undefined' &&
                navigator.maxTouchPoints > 2
            ) || null;
        },

        /**
         * Checks if the browser is Opera Mini.
         *
         * @returns {RegExpMatchArray | null} Match result or null
         */
        OperaMini(): RegExpMatchArray | null {
            return ua.match(/Opera Mini/i);
        },

        /**
         * Checks if the device matches any of the mobile device categories.
         *
         * @returns {boolean | RegExpMatchArray | null} True if any mobile device, false or null otherwise
         */
        any(): boolean | RegExpMatchArray | null {
            return (
                IsDevice.Android() ||
                IsDevice.BlackBerry() ||
                IsDevice.iOS() ||
                IsDevice.iPad() ||
                IsDevice.OperaMini() ||
                IsDevice.IEMobile()
            );
        }
    };
})();

/**
 * Type definition for event handler functions.
 *
 * @param {Event} event - The event object
 */
type EventHandler = (event: Event) => void;

/**
 * Custom hook to add and remove event listeners.
 *
 * @param {string} event_name - The name of the event to listen for
 * @param {EventHandler} handler - The event handler function
 * @param {HTMLElement | Document} [element=document] - The element to attach the listener to
 */
function useEventListener(
    event_name: string,
    handler: EventHandler,
    element: HTMLElement | Document = document
) {
    /**
     * Ref to store the current handler function.
     */
    const saved_handler = useRef<EventHandler>();

    /**
     * Effect to update the ref when the handler changes.
     */
    useEffect(() => {
        saved_handler.current = handler;
    }, [handler]);

    /**
     * Effect to add and remove the event listener.
     */
    useEffect(() => {
        const is_supported = element && element.addEventListener;
        if (!is_supported) return;

        const event_listener = (event: Event) => saved_handler.current!(event);

        element.addEventListener(event_name, event_listener);

        return () => {
            element.removeEventListener(event_name, event_listener);
        };
    }, [event_name, element]);
}

/**
 * Props interface for the CursorCore component.
 *
 * @interface CursorCoreProps
 */
interface CursorCoreProps {
    outer_style?: React.CSSProperties;
    inner_style?: React.CSSProperties;
    color?: string;
    outer_alpha?: number;
    inner_size?: number;
    outer_size?: number;
    outer_scale?: number;
    inner_scale?: number;
    trailing_speed?: number;
    clickable?: string[];
}

/**
 * Replaces the native cursor with a custom animated cursor, consisting
 * of an inner and outer dot that scale inversely based on hover or click.
 *
 * @component
 *
 * @param {CursorCoreProps} props - The props for the CursorCore component
 */
function CursorCore({
                        outer_style,
                        inner_style,
                        color = '220, 90, 90',
                        outer_alpha = 0.3,
                        inner_size = 8,
                        outer_size = 8,
                        outer_scale = 6,
                        inner_scale = 0.6,
                        trailing_speed = 8,
                        clickable = [
                            'a',
                            'input[type="text"]',
                            'input[type="email"]',
                            'input[type="number"]',
                            'input[type="submit"]',
                            'input[type="image"]',
                            'label[for]',
                            'select',
                            'textarea',
                            'button',
                            '.link'
                        ]
                    }: CursorCoreProps) {
    /**
     * Refs for cursor elements and animation.
     */
    const cursor_outer_reference = useRef<HTMLDivElement>(null);
    const cursor_inner_reference = useRef<HTMLDivElement>(null);
    const request_reference = useRef<number>();
    const previous_time_reference = useRef<number>();

    /**
     * State for cursor position and visibility.
     */
    const [coords, set_coordinates] = useState({x: 0, y: 0});
    const [is_visible, set_is_visible] = useState(false);
    const [is_active, set_is_active] = useState(false);
    const [is_active_clickable, set_is_active_clickable] = useState(false);

    /**
     * Refs for endpoint coordinates.
     */
    const end_x = useRef(0);
    const end_y = useRef(0);

    /**
     * Handler for mouse movement.
     */
    const on_mouse_move = useCallback(({clientX, clientY}: MouseEvent) => {
        set_coordinates({x: clientX, y: clientY});
        if (cursor_inner_reference.current) {
            cursor_inner_reference.current.style.top = `${clientY}px`;
            cursor_inner_reference.current.style.left = `${clientX}px`;
        }
        end_x.current = clientX;
        end_y.current = clientY;
    }, []);

    /**
     * Animation function for outer cursor.
     */
    const animate_outer_cursor = useCallback(
        (time: number) => {
            if (previous_time_reference.current !== undefined) {
                coords.x += (end_x.current - coords.x) / trailing_speed;
                coords.y += (end_y.current - coords.y) / trailing_speed;
                if (cursor_outer_reference.current) {
                    cursor_outer_reference.current.style.top = `${coords.y}px`;
                    cursor_outer_reference.current.style.left = `${coords.x}px`;
                }
            }
            previous_time_reference.current = time;
            request_reference.current = requestAnimationFrame(animate_outer_cursor);
        },
        [trailing_speed, coords]
    );

    /**
     * Effect to set up and clean up the animation frame.
     */
    useEffect(() => {
        request_reference.current = requestAnimationFrame(animate_outer_cursor);
        return () => {
            if (request_reference.current) {
                cancelAnimationFrame(request_reference.current);
            }
        };
    }, [animate_outer_cursor]);

    /**
     * Mouse event handlers.
     */
    const on_mouse_down = useCallback(() => set_is_active(true), []);
    const on_mouse_up = useCallback(() => set_is_active(false), []);
    const on_mouse_enter = useCallback(() => set_is_visible(true), []);
    const on_mouse_leave = useCallback(() => set_is_visible(false), []);

    /**
     * Set up event listeners.
     */
    useEventListener('mousemove', on_mouse_move as EventHandler);
    useEventListener('mousedown', on_mouse_down as EventHandler);
    useEventListener('mouseup', on_mouse_up as EventHandler);
    useEventListener('mouseover', on_mouse_enter as EventHandler);
    useEventListener('mouseout', on_mouse_leave as EventHandler);

    /**
     * Effect to handle cursor scaling on active state.
     */
    useEffect(() => {
        if (is_active) {
            if (cursor_inner_reference.current) {
                cursor_inner_reference.current.style.transform = `translate(-50%, -50%) scale(${inner_scale})`;
            }
            if (cursor_outer_reference.current) {
                cursor_outer_reference.current.style.transform = `translate(-50%, -50%) scale(${outer_scale})`;
            }
        } else {
            if (cursor_inner_reference.current) {
                cursor_inner_reference.current.style.transform = 'translate(-50%, -50%) scale(1)';
            }
            if (cursor_outer_reference.current) {
                cursor_outer_reference.current.style.transform = 'translate(-50%, -50%) scale(1)';
            }
        }
    }, [inner_scale, outer_scale, is_active]);

    /**
     * Effect to handle cursor scaling on clickable elements.
     */
    useEffect(() => {
        if (is_active_clickable) {
            if (cursor_inner_reference.current) {
                cursor_inner_reference.current.style.transform = `translate(-50%, -50%) scale(${inner_scale * 1.2})`;
            }
            if (cursor_outer_reference.current) {
                cursor_outer_reference.current.style.transform = `translate(-50%, -50%) scale(${outer_scale * 1.4})`;
            }
        }
    }, [inner_scale, outer_scale, is_active_clickable]);

    /**
     * Effect to handle cursor visibility.
     */
    useEffect(() => {
        if (cursor_inner_reference.current && cursor_outer_reference.current) {
            cursor_inner_reference.current.style.opacity = is_visible ? '1' : '0';
            cursor_outer_reference.current.style.opacity = is_visible ? '1' : '0';
        }
    }, [is_visible]);

    /**
     * Effect to set up clickable elements.
     */
    useEffect(() => {
        const clickable_elements = document.querySelectorAll(clickable.join(','));

        clickable_elements.forEach((element) => {
            (element as HTMLElement).style.cursor = 'none';

            element.addEventListener('mouseover', () => set_is_active(true));
            element.addEventListener('click', () => {
                set_is_active(true);
                set_is_active_clickable(false);
            });
            element.addEventListener('mousedown', () => set_is_active_clickable(true));
            element.addEventListener('mouseup', () => set_is_active(true));
            element.addEventListener('mouseout', () => {
                set_is_active(false);
                set_is_active_clickable(false);
            });
        });

        return () => {
            clickable_elements.forEach((element) => {
                element.removeEventListener('mouseover', () => set_is_active(true));
                element.removeEventListener('click', () => {
                    set_is_active(true);
                    set_is_active_clickable(false);
                });
                element.removeEventListener('mousedown', () => set_is_active_clickable(true));
                element.removeEventListener('mouseup', () => set_is_active(true));
                element.removeEventListener('mouseout', () => {
                    set_is_active(false);
                    set_is_active_clickable(false);
                });
            });
        };
    }, [is_active, clickable]);

    /**
     * Cursor styles.
     */
    const styles: { [key: string]: React.CSSProperties } = {
        cursor_inner: {
            zIndex: 999,
            display: 'block',
            position: 'fixed',
            borderRadius: '50%',
            width: inner_size,
            height: inner_size,
            pointerEvents: 'none',
            backgroundColor: `rgba(${color}, 1)`,
            ...(inner_style && inner_style),
            transition: 'opacity 0.15s ease-in-out, transform 0.25s ease-in-out'
        },
        cursor_outer: {
            zIndex: 999,
            display: 'block',
            position: 'fixed',
            borderRadius: '50%',
            pointerEvents: 'none',
            width: outer_size,
            height: outer_size,
            backgroundColor: `rgba(${color}, ${outer_alpha})`,
            transition: 'opacity 0.15s ease-in-out, transform 0.15s ease-in-out',
            willChange: 'transform',
            ...(outer_style && outer_style)
        }
    };

    /**
     * Hide global cursor.
     */
    document.body.style.cursor = 'none';

    return (
        <React.Fragment>
            <div ref={cursor_outer_reference} style={styles.cursor_outer}/>
            <div ref={cursor_inner_reference} style={styles.cursor_inner}/>
        </React.Fragment>
    );
}

/**
 * Props interface for the AnimatedCursor component.
 *
 * @interface AnimatedCursorProps
 */
interface AnimatedCursorProps extends CursorCoreProps {
}

/**
 * Calls and passes props to CursorCore if not a touch/mobile device.
 * This component acts as a wrapper to conditionally render the custom cursor.
 *
 * @component
 *
 * @param {AnimatedCursorProps} props - The props for the AnimatedCursor component
 */
function AnimatedCursor(props: AnimatedCursorProps) {
    if (typeof navigator !== 'undefined' && IsDevice.any()) {
        return <React.Fragment></React.Fragment>;
    }
    return <CursorCore {...props} />;
}

export default AnimatedCursor;