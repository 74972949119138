import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
    VscGrabber,
    VscClose
} from "react-icons/vsc";

import "./header.css";
import {
    logo_text,
    social_profiles
} from "../../global_options";

/**
 * This component represents the main header of the application.
 * It includes a logo, navigation menu, and social media links.
 *
 * @component
 */
const Header: React.FC = () => {
    /**
     * State to manage the active/inactive state of the menu.
     */
    const [is_active, set_is_active] = useState<boolean>(false);
    /**
     * Handles the menu toggle.
     * Toggles the is_active state and adds/removes the 'ovhidden' class on the body.
     */
    const handle_toggle = () => {
        set_is_active(!is_active);
        document.body.classList.toggle("ovhidden");
    };

    return (
        <>
            <header className="fixed-top site__header">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="navbar-brand nav_ac" to="/">
                        {logo_text}
                    </Link>
                    <div className="d-flex align-items-center">
                        <button className="menu__button nav_ac" onClick={handle_toggle}>
                            {is_active ? <VscClose/> : <VscGrabber/>}
                        </button>
                    </div>
                </div>

                <div className={`site__navigation ${is_active ? "menu__opend" : ""}`}>
                    <div className="bg__menu h-100">
                        <div className="menu__wrapper">
                            <div className="menu__container p-3">
                                <ul className="the_menu">
                                    <li className="menu_item">
                                        <Link onClick={handle_toggle} to="/" className="my-3">Home</Link>
                                    </li>
                                    <li className="menu_item">
                                        <Link onClick={handle_toggle} to="/about" className="my-3">About</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div
                        className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
                        <div className="d-flex">
                            <a href={social_profiles.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                        </div>
                    </div>
                </div>
            </header>
            {/* Decorative border elements */}
            <div className="br-top"></div>
            <div className="br-bottom"></div>
            <div className="br-left"></div>
            <div className="br-right"></div>
        </>
    );
};

export default Header;