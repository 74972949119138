import React, {
    useEffect,
    ReactNode
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    BrowserRouter as Router,
    useLocation
} from "react-router-dom";

import "./App.css";
import AppRoutes from "./routes";
import Header from "../components/header/header";
import WithRouter from "../hooks/with_router";
import AnimatedCursor from "../hooks/animated_cursor";

/**
 * Props interface for the ScrollToTop component.
 *
 * @interface ScrollToTopProps
 */
interface ScrollToTopProps {
    /** The child elements to be rendered */
    children: ReactNode;
}

/**
 * This component scrolls the window to the top whenever the pathname changes.
 * It's typically used to ensure the page starts at the top when navigating between routes.
 *
 * @component
 *
 * @param {ScrollToTopProps} props - The component props
 */
function ScrollToTopFC({children}: ScrollToTopProps) {
    const {pathname} = useLocation();

    /**
     * Effect to scroll to the top of the page when pathname changes
     * This effect runs when the pathname changes
     */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>{children}</>;
}

/**
 * ScrollToTop component wrapped with router functionality
 */
const ScrollToTop = WithRouter(ScrollToTopFC as React.ComponentType<any>);

/**
 * This is the root component of the application. It sets up the router,
 * includes the animated cursor, and renders the main structure of the app.
 *
 * @component
 */
const App: React.FC = () => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            {/* Animated cursor container */}
            <div className="cursor__dot">
                <AnimatedCursor
                    inner_size={8}
                    outer_size={8}
                    color="255, 255 ,255"
                    outer_alpha={0.1}
                    inner_scale={0.25}
                    outer_scale={5}
                />
            </div>
            {/* Main application structure */}
            <ScrollToTop>
                <Header/>
                <AppRoutes/>
            </ScrollToTop>
        </Router>
    );
}

export default App;